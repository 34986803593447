import { Component } from 'react'
import { camelCase } from 'lodash-es'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'

import { hideNotification } from '../store/actions'
import compose from '../utils/compose'
import translate from '../utils/translate'
import withI18n from '../components/withI18n'

export class NotificationOverlayRaw extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    notifications: ImmutablePropTypes.list.isRequired,
  }

  render() {
    const { t, dispatch, notifications } = this.props
    return (
      <div className="ep-alert-overlay">
        {notifications
          .filter((n) => n.get('level') === 'error')
          .map((err) => {
            const errorMessage = t('notifications.' + camelCase(err.get('message')))
            const needsErrorId = errorMessage.endsWith(t('notifications.idExplanation'))

            return (
              <div className="ep-alert-info" key={err.get('id')}>
                <span
                  title={t('notifications.dismissButton.tooltip')}
                  className="ep-alert-info-close"
                  onClick={() => dispatch(hideNotification(err.get('id')))}
                />
                {errorMessage}
                {needsErrorId && <code> {err.get('serverRequestId')}</code>}
              </div>
            )
          })}
      </div>
    )
  }
}

export default compose(
  withI18n('shop'),
  translate(),
  connect((state) => ({
    notifications: state.getIn(['view', 'notifications']),
  })),
)(NotificationOverlayRaw)

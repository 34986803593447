import type { ReactElement } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import Immutable from 'immutable'
import axios from 'axios'
import cc from 'classcat'
import loadable from '@loadable/component'

import { extractVimeoId, extractYoutubeId } from './extractVideoId'
import compose from '../../../../../utils/compose'
import translate from '../../../../../utils/translate'
import withI18n from '../../../../withI18n'

const SettingsLayer = loadable(() => import(/* webpackChunkName: "editor" */ '../../SettingsLayer'))
const SettingsForm = loadable(() => import(/* webpackChunkName: "editor" */ './VideoSettings'))

function VideoPreview({ thumbnailUrl }: Readonly<{ thumbnailUrl: string | Promise<string> }>): ReactElement {
  const [url, setUrl] = useState(typeof thumbnailUrl === 'string' ? thumbnailUrl : null)

  useEffect(() => {
    if (thumbnailUrl instanceof Promise) {
      thumbnailUrl.then(setUrl)
    }
  }, [thumbnailUrl])

  return (
    <div className="dali-plugin-video-container">
      <div
        style={{
          backgroundImage: `url("${url}")`,
        }}
        className="dali-plugin-video-thumbnail"
      />
    </div>
  )
}

function VideoEmbedFrame({ url }: Readonly<{ url: string }>): ReactElement {
  return (
    <div className="dali-plugin-video-container">
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        className="dali-plugin-video-content lazyload"
        data-src={url}
        // Suppress hydration warning because some prop values will
        // unavoidably be different between the server and the client.
        // lazysizes will change these attributes on the client.
        suppressHydrationWarning={true}
        frameBorder="0"
        allowFullScreen={true}
      />
    </div>
  )
}

function generateYoutubeEmbedUrl(id: string) {
  return `https://www.youtube.com/embed/${id}?rel=0&autohide=1&showinfo=0&wmode=opaque`
}

function generateYoutubeNoCookieEmbedUrl(id: string) {
  return `https://www.youtube-nocookie.com/embed/${id}?rel=0&autohide=1&showinfo=0&wmode=opaque`
}

function generateYoutubeThumbnailUrl(id: string) {
  return `https://img.youtube.com/vi/${id}/maxresdefault.jpg`
}

async function generateVimeoThumbnailUrl(id: string): Promise<string> {
  return axios
    .get(`https://vimeo.com/api/v2/video/${id}.json`)
    .then((response) => response.data[0].thumbnail_large)
    .catch(() => '')
}

function generateVimeoEmbedUrl(id: string) {
  return `https://player.vimeo.com/video/${id}?portrait=0`
}

function getVideoData(url: string) {
  const youtubeId = extractYoutubeId(url)
  const vimeoId = extractVimeoId(url)

  if (youtubeId) {
    const isNoCookie = url.includes('youtube-nocookie.com')

    return {
      id: youtubeId,
      thumbnailUrl: generateYoutubeThumbnailUrl(youtubeId),
      embedUrl: isNoCookie ? generateYoutubeNoCookieEmbedUrl(youtubeId) : generateYoutubeEmbedUrl(youtubeId),
    }
  } else if (vimeoId)
    return {
      id: vimeoId,
      thumbnailUrl: generateVimeoThumbnailUrl(vimeoId),
      embedUrl: generateVimeoEmbedUrl(vimeoId),
    }
}

function VideoPlugin({
  data = Immutable.fromJS({ src: '' }),
  editorMode,
  editorView,
  onEdit,
  onCancel,
  onDataChange,
  onSave,
  t,
}: WorkspacePluginProps & TranslateProps): ReactElement | null {
  const [isSettingActive, setIsSettingActive] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const src = data.get('src')

  const pluginActiveClasses = cc([
    'dali-plugin-video',
    {
      'dali-grid-element-highlighted': isSettingActive,
    },
  ])

  const video = useMemo(() => getVideoData(src), [src])

  const videoPlaceholder = (
    <div className="dali-grid-element-placeholder">
      <span className="dali-plugin-video-placeholder" />
      <button className="dali-plugin-video-placeholder-button-add" onClick={() => onEdit()}>
        {t('components.videoElementComponent.addVideoButton.label')}
      </button>
    </div>
  )

  const renderSettingsLayer = () => {
    return (
      <SettingsLayer
        referenceElement={ref.current}
        placement="right"
        onActiveStateChange={(isSettingActive: boolean) => setIsSettingActive(isSettingActive)}
        onEscapeKeyDown={onCancel}
      >
        {({ renderLayout }) => <SettingsForm {...{ data, onDataChange, onSave, onCancel, renderLayout }} />}
      </SettingsLayer>
    )
  }

  if (editorView) {
    return (
      <div className={pluginActiveClasses} ref={ref}>
        {!video ? videoPlaceholder : <VideoPreview key={video.id} thumbnailUrl={video.thumbnailUrl} />}
        {editorMode === 'edit' ? renderSettingsLayer() : null}
      </div>
    )
  }

  return !video ? null : (
    <div className={pluginActiveClasses}>
      <VideoEmbedFrame url={video.embedUrl} />
    </div>
  )
}

VideoPlugin.actionBarButtons = { edit: true }

export default compose(withI18n('interface'), translate())(VideoPlugin)

import type { LinkHTMLAttributes, PropsWithChildren, ReactElement } from 'react'
import type { RouterState } from 'react-router'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'

import { DocumentMeta } from '../components/DocumentMeta'
import { getDefaultThemeSettings } from '../store/actions'
import CookieConsent from '../components/CookieConsent'
import ErrorPage from '../components/ErrorPage'
import NotificationOverlay from '../components/NotificationOverlay'
import PreviewBar from '../components/PreviewBar'
import XhrLoadingBar from '../components/XhrLoadingBar'
import compose from '../utils/compose'
import translate from '../utils/translate'
import withI18n from '../components/withI18n'

type Props = {
  viewError?: ImmutableMap
  viewErrorMessage?: string
  isShopClosed: boolean
  isBeyond: boolean
  isPreview: boolean
  isEditor: boolean
  isThemePreview?: boolean
  shopFaviconUrl: string
} & TranslateProps

function StorefrontRaw({
  t,
  children,
  isBeyond,
  isEditor,
  isPreview,
  isShopClosed,
  viewError,
  viewErrorMessage,
  isThemePreview,
  shopFaviconUrl,
}: Readonly<PropsWithChildren<Props>>): ReactElement {
  // isIframe defaults to true in order to avoid a layout shift in the mobile preview.
  // This creates a layout shift in the test order preview instead, but there it's even helpful.
  const [isIframe, setIsIframe] = useState(true)
  useEffect(() => setIsIframe(window.parent !== window), [])

  if (
    // see error.ts in fusion-backend-ts
    viewErrorMessage === 'The requested shop is currently closed' ||
    (isBeyond && isShopClosed && !isPreview && !isThemePreview && !isEditor && !isIframe)
  ) {
    return (
      <div className="ep-shop-closed">
        <DocumentMeta />
        <h1 className="ep-shop-closed-headline">{t('views.storefrontClosedView.explanation')}</h1>
        <div className="ep-shop-closed-image" />
      </div>
    )
  }

  if (viewError?.get('statusCode') === 429) {
    return (
      <div data-testid="429-error-page">
        <DocumentMeta />
        <ErrorPage />
      </div>
    )
  }

  const shouldRenderPreviewBar = isBeyond && (isPreview || isThemePreview) && !isEditor && !isIframe

  return (
    <>
      {getFaviconLinks(shopFaviconUrl).map((link) => (
        <link key={link.href} {...link} />
      ))}
      <div className="ep-storefront-wrapper">
        <XhrLoadingBar />
        {shouldRenderPreviewBar && <PreviewBar />}
        {children}
        <CookieConsent isEditor={isEditor} />
      </div>
      {/* Make sure notification overlay is always on top of storefront */}
      <NotificationOverlay />
    </>
  )
}

StorefrontRaw.storeUpdate = (_props: unknown, state: State) => [
  !state.get('defaultThemeSettings') ? getDefaultThemeSettings(state.getIn(['shop', 'themeId'])) : null,
]

StorefrontRaw.loadableSsrChunks = (_props: RouterState, state: State) =>
  state.getIn(['shop', 'beyond']) ? ['account'] : []

function getFaviconLinks(shopFaviconUrl: string): LinkHTMLAttributes<HTMLLinkElement>[] {
  const isIcoFavicon = shopFaviconUrl?.endsWith('.ico')

  if (isIcoFavicon || !shopFaviconUrl) {
    return [
      {
        rel: 'shortcut icon',
        href: shopFaviconUrl || 'data:image/x-icon;,',
        type: 'image/x-icon',
      },
    ]
  }

  if (!isIcoFavicon && shopFaviconUrl) {
    return [
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: `${shopFaviconUrl}&width=16&height=16`,
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: `${shopFaviconUrl}&width=32&height=32`,
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '96x96',
        href: `${shopFaviconUrl}&width=96&height=96`,
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: `${shopFaviconUrl}&width=180&height=180`,
      },
    ]
  }

  return []
}

export default compose(
  withI18n('shop'),
  translate(),
  connect((state: State) => ({
    shopFaviconUrl: state.getIn(['shop', 'faviconUrl']),
    isShopClosed: state.getIn(['shop', 'isClosed']),
    isBeyond: Boolean(state.getIn(['shop', 'beyond'])),
    isPreview: Boolean(state.getIn(['location', 'query', 'preview'])),
    isEditor: state.getIn(['view', 'editorMode']),
    isThemePreview: state.getIn(['view', 'isThemePreview']),
    viewError: state.getIn(['view', 'error']),
    viewErrorMessage: state.getIn(['view', 'error', 'message']),
  })),
)(StorefrontRaw)

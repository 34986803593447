import { Component } from 'react'
import type { ReactElement } from 'react'
import cc from 'classcat'

import Link from '../../templateComponents/Link'
import translate from '../../../utils/translate'

type MegaMenuItemProps = Readonly<
  {
    item: Frontend.NestedPage
  } & TranslateProps
>

type MegaMenuItemState = {
  isOpen: boolean
  openedLevel4: string | null
  offsetHeight: number | null
}

class MegaMenuItem extends Component<MegaMenuItemProps, MegaMenuItemState> {
  private domNode
  private canvasElement
  private pageContainerElement
  private megaMenuNode
  private megaMenuLastScrollPosition

  state = {
    isOpen: false,
    openedLevel4: null,
    offsetHeight: null,
  }

  level4Elements = {}

  closeMegaMenu = (event) => {
    if (!this.domNode.contains(event.target) && this.state.isOpen) {
      this.setState({ isOpen: false })
    }
  }

  componentDidMount() {
    this.setState({
      offsetHeight: this.canvasElement.offsetHeight,
    })

    // The click event handler is not bound on `document.body` because Safari on iOS
    // only allows mouse events to bubble up in specific situations. More information:
    //   http://www.quirksmode.org/blog/archives/2014/02/mouse_event_bub.html#link2
    this.pageContainerElement = document.querySelector('.body')
    this.pageContainerElement.addEventListener('click', this.closeMegaMenu)
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({
      isOpen: false,
      offsetHeight: this.canvasElement.offsetHeight,
    })
  }

  componentWillUnmount() {
    this.pageContainerElement.removeEventListener('click', this.closeMegaMenu)
  }

  toggleMegaMenu = (event) => {
    // if the click event just opened it prevent the click from triggering a navigation
    if (!this.state.isOpen) event.preventDefault()

    this.setState({ isOpen: !this.state.isOpen })
  }

  slideItem(itemId: string | null) {
    // setting scroll to 0 in case if level4-menu is about to be open
    if (itemId) {
      this.megaMenuLastScrollPosition = this.megaMenuNode.scrollTop
      this.megaMenuNode.scrollTop = 0
    } else {
      this.megaMenuNode.scrollTop = this.megaMenuLastScrollPosition
    }

    this.setState({
      openedLevel4: itemId,
      offsetHeight: itemId
        ? Math.max(this.state.offsetHeight ?? 0, this.level4Elements[itemId].offsetHeight)
        : this.canvasElement.offsetHeight,
    })
  }

  renderLevel4Container = (item: Frontend.NestedPage, parentItem: Frontend.NestedPage) => {
    const { openedLevel4 } = this.state
    const hasChildren = item.children.length > 0
    const menuId = `level4-menu-${item.id}`

    return (
      <div
        key="level4-container"
        ref={(node) => {
          this.level4Elements[item.id] = node
        }}
        id={menuId}
        className={cc([
          'mega-menu-level4-container',
          {
            'mega-menu-level4-container-open': openedLevel4 === item.id,
          },
        ])}
        style={openedLevel4 ? { height: this.megaMenuNode.clientHeight } : {}}
      >
        <div className="mega-menu-level4-container-breadcrumb">
          <Link to={parentItem.href}>
            {parentItem.title}
            <span className="mega-menu-level4-container-breadcrumb-separator" aria-hidden="true" />
          </Link>
          <Link to={item.href}>{item.title}</Link>
          <button
            className="mega-menu-level4-close"
            aria-controls={menuId}
            aria-expanded={Boolean(openedLevel4)}
            onClick={() => this.slideItem(null)}
          >
            <span className="mega-menu-level4-close-icon" aria-hidden="true" />
            <span className="visually-hidden">{this.props.t('closeSubmenuButton.accessibilityLabel')}</span>
          </button>
        </div>
        {hasChildren && this.renderList(item.children, item, 4)}
      </div>
    )
  }

  renderList(items: Frontend.NestedPage[], parentItem: Frontend.NestedPage, level = 2) {
    const nextLevel = level + 1

    return (
      <ul className={`mega-menu-level${level}`}>
        {items.map((item) => {
          const hasChildren = item.children.length > 0
          const dropIcon = (
            <button
              key="expand-icon"
              className="drop-icon"
              aria-controls={`level4-menu-${item.id}`}
              aria-expanded={Boolean(this.state.openedLevel4)}
              onClick={() => this.slideItem(item.id)}
            >
              <span className="drop-icon-icon" aria-hidden="true" />
              <span className="visually-hidden">{this.props.t('openSubmenuButton.accessibilityLabel')}</span>
            </button>
          )

          return (
            <li key={item.id}>
              <Link to={item.href}>{item.title}</Link>

              {hasChildren && level < 3 && this.renderList(item.children, item, nextLevel)}
              {hasChildren && level === 3 && [dropIcon, this.renderLevel4Container(item, parentItem)]}
            </li>
          )
        })}
      </ul>
    )
  }

  render() {
    const { isInBreadcrumb, children, href, title, id } = this.props.item
    const { isOpen, offsetHeight, openedLevel4 } = this.state

    const menuClass = cc(['mega-menu', { 'mega-menu-open': isOpen, 'mega-menu-level4-active': openedLevel4 }])
    const menuId = `menu-${id}`
    const canvasClass = cc(['mega-menu-canvas', { 'mega-menu-canvas-up': openedLevel4 }])
    const hasChildren = children.length > 0

    // The link acts as a control for the associated sub menu (if present).
    // It is a link when the sub menu is open, otherwise it is a button
    // (indicated with the button role) that opens the sub menu when clicked.
    const linkButtonProps = hasChildren
      ? {
          'aria-controls': menuId,
          'aria-expanded': isOpen,
          role: isOpen ? undefined : 'button',
          onClick: (event) => this.toggleMegaMenu(event),
        }
      : {}

    return (
      <li
        ref={(node) => {
          this.domNode = node
        }}
        className={cc({
          active: isInBreadcrumb,
          'has-sub-menu': hasChildren,
          open: isOpen,
        })}
      >
        <Link to={href} {...linkButtonProps}>
          <span>{title}</span>
          <span className="down-icon" />
        </Link>
        <div
          ref={(node) => {
            this.megaMenuNode = node
          }}
          id={menuId}
          className={menuClass}
          style={{ height: offsetHeight ?? undefined }}
          tabIndex={-1}
        >
          <div
            ref={(node) => {
              this.canvasElement = node
            }}
            className={canvasClass}
          >
            {this.renderList(children, this.props.item, 2)}
          </div>
        </div>
      </li>
    )
  }
}

type MegaMenuProps = {
  items: Frontend.NestedPage[]
  className?: string
} & TranslateProps

function MegaMenu({ items, className, t }: Readonly<MegaMenuProps>): ReactElement {
  return (
    <div className={className}>
      <div className="main-menu-wrapper">
        <ul className="main-menu">
          {items.map((item) => (
            <MegaMenuItem key={item.id} item={item} t={t} />
          ))}
        </ul>
      </div>
    </div>
  )
}

export default translate('components.storefrontMainMenuComponent')(MegaMenu)

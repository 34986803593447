import type { ReactNode } from 'react'
import type { RouteComponentProps } from 'react-router'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import { DocumentMeta } from '../components/DocumentMeta'
import { addLineItemProductList, addPurchase, setCurrencyCode } from '../utils/googleAnalytics'
import { getPlain } from '../store/utils'
import type { loadCart } from '../store/actions'
import { loadOrder } from '../store/actions'
import { track } from '../utils/tracking'
import Link from '../components/templateComponents/Link'
import NewsletterForm from '../components/templateComponents/NewsletterForm'
import Theme from '../components/Theme'
import compose from '../utils/compose'
import translate from '../utils/translate'
import withI18n from '../components/withI18n'

type Props = RouteComponentProps<{ orderId?: string }, void> & TranslateProps
function OrderRaw({ t, params }: Props): ReactNode {
  const viewError = useSelector<State, ImmutableMap>((state) => state.getIn(['view', 'error']))
  const hasTracked = useRef(false)
  const shop = useSelector<State, ImmutableMap>((state) => state.get('shop'))
  const order = getPlain<Core.Order>(
    useSelector<State, ImmutableMap>((state) => state.getIn(['orders', params.orderId || 'byd'])),
  )

  useEffect(() => {
    if (order && !hasTracked.current) {
      hasTracked.current = true
      track('order:completed', order)
      setCurrencyCode(order.currencyId)
      addLineItemProductList(order.lineItemContainer.productLineItems)
      addPurchase(order, shop.get('title'))
    }
  }, [shop, order])

  if (viewError) return <Theme withLayout error={viewError} currentView="order" />

  return (
    <div>
      <DocumentMeta
        title={`${t('views.storefrontView.orderConfirmationPageSection.title')} - ${shop.get('title')}`}
        meta={[
          {
            name: 'robots',
            content: 'noindex, follow',
          },
          // Strip the order id from the referrer
          {
            name: 'referrer',
            content: 'origin',
          },
        ]}
      />
      <Theme withLayout currentView="Order">
        {(renderView, props) =>
          order ? (
            renderView('Order', { ...props, order })
          ) : (
            // Order service not available: "just" display a thank-you-for-ordering message
            <div className="order beyond">
              <div className="section">
                <div className="wrapper">
                  <div className="content">
                    <h1>{t('views.storefrontView.orderConfirmationPageSection.title')}</h1>
                    <header className="order-confirmation">
                      <div className="order-confirmation-status">
                        <div className="status-order">
                          <h2>{t('views.storefrontView.orderConfirmationPageSection.successStatus.orderReceived')}</h2>
                        </div>
                        <NewsletterForm className="status-newsletter-form">
                          <div className="status-newsletter">
                            <h2>{t('components.newsletterSubscriptionComponent.title')}</h2>
                            <div className="status-newsletter-form-register">
                              <input
                                className="status-newsletter-form-field"
                                name="email"
                                type="email"
                                required
                                placeholder={t(
                                  'components.newsletterSubscriptionComponent.emailAddressInputField.placeholder',
                                )}
                              />
                              <button className="status-newsletter-form-button" type="submit">
                                <span>{t('components.newsletterSubscriptionComponent.submitButton.label')}</span>
                              </button>
                            </div>
                          </div>
                        </NewsletterForm>
                        <div className="status-home">
                          <h2>{t('views.storefrontView.orderConfirmationPageSection.successStatus.whatNext')}</h2>
                          <Link className="status-home-link" to="/">
                            {t('views.storefrontView.returnToStartpageLink.label')}
                          </Link>
                        </div>
                      </div>
                    </header>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Theme>
      {/** Trusted Shops order confirmation badge, altered by an injected script **/}
      {order && (
        <div id="trustedShopsCheckout" style={{ display: 'none' }}>
          <span id="tsCheckoutOrderNr">{order.orderNumber}</span>
          <span id="tsCheckoutBuyerEmail">{order.billingAddress.email}</span>
          <span id="tsCheckoutOrderAmount">{order.grandTotal}</span>
          <span id="tsCheckoutOrderCurrency">{order.currencyId}</span>
          <span id="tsCheckoutOrderPaymentType">{order.paymentData.paymentMethod?.name}</span>
        </div>
      )}
    </div>
  )
}

OrderRaw.storeUpdate = (props: Props, state: State) => {
  const isForwardNavigation = props.location.action === 'PUSH'
  const updates: [ReturnType<typeof loadCart>?, ReturnType<typeof loadOrder>?] = []

  if (isForwardNavigation || !state.getIn(['orders', props.params.orderId || 'byd'])) {
    updates.push(loadOrder(props.params.orderId || 'byd', props.location.query))
  }

  return updates
}

OrderRaw.loadableSsrChunks = ['checkout']
OrderRaw.contentCreationDisabled = () => true

export default compose(withI18n('shop'), translate())(OrderRaw)
